
//http://localhost:8080/#/claim/EnquiryProgram
import Detail2 from './Detail/index2.vue';
import { defineComponent, ref, reactive, toRefs, nextTick, h, computed, createVNode } from 'vue';
import { getQueryList, deleteInfo } from '@/API/claim/claimQuery';
import {
  getPaypreDetailLst,
  getModelYear,
  getRejectToSICCO,
  getSubmitToCon,
  getDetilRejectToSICCO,
  postDetilRejectToSICCO,
  getDetilSubmitToCon,
  postDetilSubmitToCon,
  getUser,
  getDetailCheckList,
  getErrorTypeNum
} from '@/API/checking/paymentPreparation';
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
// import setColumnWidth from "@/utils/claim/setColumnWidth"
import downloadFile from '@/utils/claim/downloadFile';
import { AFCandLCApproveTableData } from '@/views/Payment/Mgmt/types';
import { useRoute, useRouter } from 'vue-router';
import { docDelete } from '@/API/claim/claimDocumentSample';
import moment, { Moment } from 'moment';
import MultiInput from '../components/MultiInput.vue';
import RetailEligibilityModal from './components/RetailEligibilityModal.vue';
// import axios from "axios";
const tableWidth = window.innerWidth;
const columns = reactive([
  { title: 'Region', dataIndex: 'region', width: 150 },
  { title: 'Dealer Name', dataIndex: 'dealerNameEn', width: 250 },
  { title: 'Dealer Code', dataIndex: 'dealerCode', width: 150 },
  { title: 'VIN', dataIndex: 'vinNo', width: 180 },
  { title: 'Model', dataIndex: 'modelName', width: 250 },
  { title: 'Model Year', dataIndex: 'modelYear', width: 150 },
  { title: 'Dealer Trade', dataIndex: 'dealerTrade', width: 120 },
  { title: 'WS Dealer Code', dataIndex: "dealerTradeWholeSaleCode", width: 160 },
  { title: 'WS Dealer Name', dataIndex: "dealerTradeWholeSaleNameCn", width: 260 },
  { title: 'Check Result', dataIndex: 'checkResult', width: 150 },
  { title: 'Status', dataIndex: 'status', width: 150 },
  { title: 'Final Bonus', dataIndex: 'finalBonus', width: 150 },
  { title: 'Return Time', dataIndex: 'paymentReturnTime', width: 150 },
  { title: 'Automatic Deduction', dataIndex: 'autoDeduction', width: 150 },
  { title: 'Exemption Deadline', dataIndex: 'exemptionDateStr', width: 150 },
  { title: 'Exceed Exemption Deadline', dataIndex: 'exceed', width: 200 },
  { title: 'Retail Eligibility in OASIS', dataIndex: 'retailEligibility', width: 200 },
  { title: 'Retail Check Error Type', dataIndex: 'retailCheckErrorTypeShow', width: 240 },
  { title: 'Retail Date', dataIndex: 'retailCheckDateShow', width: 150 },
  { title: 'Information Updated in SOP', dataIndex: 'mismatchDateShow', width: 200 },

  {
    title: 'Operation',
    dataIndex: 'checkResult',
    width: 150,
    slots: { customRender: 'operation' }
  }
]);
const coverColumns = reactive([
  {
    title: 'No',
    dataIndex: 'programCode',
    slots: { customRender: 'No' }
  },
  { title: 'Program Code', dataIndex: 'programCode' },
  { title: 'Program Category', dataIndex: 'programCategory' },
  { title: 'Qty', dataIndex: 'qty' }
]);

interface TableType {
  dealerCode: string;
  dealerNameCn: string;
  programCode: string;
  eligiblePeriodStr: string;
  offerTypeName: string;
  optionName: string;
  vinNo: string;
  model: string;
  inParagraph: string;
  payRound: string;
  checkResult: string;
  complaintStatus: string;
  predictAmount: string;
  price: string;
  createdTime: string;
  status: string;
}
export default defineComponent({
  components: { Detail2, MultiInput, RetailEligibilityModal },
  setup() {
    const checkVisible = ref(false);
    const checkDataSource = ref<any>([]);
    const checkData = reactive({});

    const deatilModal = ref<any>(null);
    const route = useRoute();
    const routerParams = reactive({
      progDesignId: route.query.progDesignId,
      offerTypeId: route.query.offerTypeId,
      programBus: route.query.programBus,
      programBuIds: route.query.programBuIds,
      programCode: route.query.programCode,
      programName: route.query.programName,
      programCategory: route.query.programCategory
    });
    const searchCon = ref();
    const tableHeight = ref();
    //筛选条件，各个信息框的内容
    const queryListParams = reactive({
      dealerCode: '',
      dealerName: '',
      vin: '', //TODO delete
      vinNoList: [],
      model: '',
      modelYear: [],
      exemptionDateFrom: '' as any,
      exemptionDateTo: '' as any,
      exceedExemptionDate: null as any,
      automaticDeduction: null as any,
      queryDealerTrade: null as any,
      retailCheckErrorTypeList: []
    });

    const changeVins = (vals: any) => {
      queryListParams.vinNoList = vals;
    };
    //显示的表格数据
    const queryListData = ref<TableType[]>([]);
    //分页组件
    const pagination = reactive({
      pageSize: 20,
      currentPage: 0,
      total: 0
    });
    //定义初始值
    const listParams = reactive({
      current: 0,
      size: 20,
      programBus: routerParams.programBus ? (routerParams.programBus as string).split(',') : [],
      offerTypeId: routerParams.offerTypeId ? routerParams.offerTypeId : '',
      progDesignId: routerParams.progDesignId ? routerParams.progDesignId : '',
      modelYears: [],
      modelName: '',
      dealerName: '',
      dealerCode: '',
      vinNo: '',
      vinNoList: [],
      exemptionDateBegin: '' as any,
      exemptionDateEnd: '' as any,
      exceed: null as any,
      autoDeduction: null as any,
      queryDealerTrade: null as any,
      retailCheckErrorTypeList: [0]
    });
    const selectTableIds = ref<any>([]);
    const dataSource = ref<any>([]);
    const sumVal = reactive({
      qtySum: 0
    });
    const programCode = ref<any>('');
    const programName = ref<any>('');
    const programCategory = ref<any>('');
    const modelYearLst = ref<any>([]);
    const ErrorTypeList = ref<any>([]);
    const isBtnCover = ref<any>(false);
    const coverInfo = ref<any>({
      coverTit: ''
    });
    const coverDataSource = ref<any>([]);
    const chooseTableLst = ref<any>([]);
    //tableRowSelection配置项
    const rowSelection = reactive<{
      selectedRowKeys: [];
      selectedRowData: AFCandLCApproveTableData[];
      onChange: Function;
      columnWidth: string;
      fixed: boolean;
    }>({
      onChange: (selectedRowKeys: [], selectedRows: AFCandLCApproveTableData[]) => {
        chooseTableLst.value = selectedRows;
        rowSelection.selectedRowKeys = selectedRowKeys;
        rowSelection.selectedRowData = selectedRows;
      },
      selectedRowKeys: [],
      selectedRowData: [],
      columnWidth: '40px',
      fixed: false
    });
    const getModleYear = () => {
      getModelYear().then((res) => {
        const dataArr = ref<any>([]);
        res.forEach((i: any, ind: any) => {
          if (i !== null) {
            dataArr.value.push(i);
          }
        });
        modelYearLst.value = dataArr.value;
      });
    };
    getModleYear();

    const getCheckErrorType = () => {
      getErrorTypeNum().then((res) => {
        // console.log(res);
        const dataArr = ref<any>([]);

        Object.entries(res).forEach((item: any) => {
          dataArr.value.push({ key: item[0], value: item[1] });
          //console.log(dataArr.value);
        });
        ErrorTypeList.value = dataArr.value;
      });
    };
    getCheckErrorType();
    // 获取params
    const getParams = () => {
      listParams.vinNo = '';
      listParams.dealerCode = '';
      listParams.dealerName = '';
      listParams.modelName = '';
      listParams.modelYears = [];
      listParams.current = pagination.currentPage;
      listParams.size = pagination.pageSize;
      listParams.vinNoList = queryListParams.vinNoList;
      listParams.retailCheckErrorTypeList = [];

      queryListParams.dealerCode !== null && queryListParams.dealerCode !== ''
        ? (listParams.dealerCode = queryListParams.dealerCode)
        : '';
      queryListParams.dealerName !== null && queryListParams.dealerName !== ''
        ? (listParams.dealerName = queryListParams.dealerName)
        : '';
      queryListParams.vin !== null && queryListParams.vin !== '' ? (listParams.vinNo = queryListParams.vin) : '';
      queryListParams.model !== null && queryListParams.model !== ''
        ? (listParams.modelName = queryListParams.model)
        : '';

      listParams.exemptionDateBegin = queryListParams.exemptionDateFrom;
      listParams.exemptionDateEnd = queryListParams.exemptionDateTo;
      listParams.exceed = queryListParams.exceedExemptionDate || '';
      listParams.autoDeduction = queryListParams.automaticDeduction || '';
      listParams.queryDealerTrade = queryListParams.queryDealerTrade || '';
      if (queryListParams.modelYear.length > 0) {
        listParams.modelYears = queryListParams.modelYear;
      } else {
        listParams.modelYears = [];
      }
      if (queryListParams.retailCheckErrorTypeList.length > 0) {
        listParams.retailCheckErrorTypeList = queryListParams.retailCheckErrorTypeList.map(Number);
      } else {
        listParams.retailCheckErrorTypeList = [];
      }
    };
    //根据筛选框信息，查询表格
    const getTableData = () => {
      getParams();
      getPaypreDetailLst(listParams).then((res) => {
        queryListData.value = res.data;
        pagination.total = res.totalNum;
      });
    };
    getTableData();
    const clearQueryListParams = () => {
      queryListParams.dealerCode = '';
      queryListParams.dealerName = '';
      queryListParams.vin = '';
      queryListParams.vinNoList = [];
      queryListParams.model = '';
      queryListParams.modelYear = [];
      queryListParams.automaticDeduction = null;
      queryListParams.queryDealerTrade = null;
      queryListParams.exceedExemptionDate = null;
      queryListParams.exemptionDateFrom = '';
      queryListParams.exemptionDateTo = '';
      queryListParams.retailCheckErrorTypeList = [];
      pagination.pageSize = 20;
      pagination.currentPage = 0;
      pagination.total = 0;
    };
    //更改分页组件，包括：直接跳转到指定页，上一页下一页
    const changePagination = (page: number, pagesize: number) => {
      pagination.pageSize = pagesize;
      pagination.currentPage = page - 1 > 0 ? page - 1 : 0;
      getTableData();
    };
    //更改每页size
    const changePageSize = (page: number, pagesize: number) => {
      pagination.pageSize = pagesize;
      pagination.currentPage = page - 1 > 0 ? page - 1 : 0;
      getTableData();
    };
    //下载
    const exportResult = () => {
      getParams();
      const timeStr = moment(new Date()).format('YYYYMMDDhhmmss');
      const paramsConfig = {
        url: `/claimapi/checking/program/export`,
        method: 'post',
        params: listParams,
        fileName: `Export_Program_Enquiry_${timeStr}.xlsx`
      };
      downloadFile(paramsConfig, 'application/vnd-excel; char-set=UTF-8');
    };
    nextTick(() => {
      (document.getElementsByClassName('table-box')[0] as any).style.height =
        window.innerHeight - 220 - searchCon.value.$el.scrollHeight + 'px';
      const height = (document.getElementsByClassName('table-box')[0] as any).style.height;
      const heightNum = parseInt(height.substr(0, height.indexOf('px')));
      tableHeight.value = heightNum - 160;
    });
    //关闭Cover
    /*const closeBtnCover = () => {
            isBtnCover.value=false
        }*/
    //打开cover
    const openBtnCover = (type: any) => {
      isBtnCover.value = true;
      if (type === 'rejecttosicco') {
        coverInfo.value.coverTit = 'Reject To SICCO';
        coverInfo.value.type = 'rejecttosicco';
      } else if (type === 'submittocontrolling') {
        coverInfo.value.coverTit = 'Submit To Controlling';
        coverInfo.value.type = 'submittocontrolling';
      }
    };
    const dataLen = computed(() => {
      return dataSource.value.length - 1;
    });
    const renderNo = ({ index }: any) => {
      const obj = {
        children: index + 1,
        props: {} as any
      };

      if (index === dataLen.value) {
        return h('span', { class: 'weight' }, `Total`);
      }

      return obj;
    };
    const renderQty = ({ text, index }: any) => {
      const obj = {
        children: text,
        props: {} as any
      };

      if (index === dataLen.value) {
        // obj.props.colSpan = 0;
        return h('span', { class: 'weight' }, sumVal.qtySum);
      }

      return obj;
    };
    const handleExport = () => {
      getParams();
      const exportParam = {
        searchReqVO: listParams,
        checkingReqVo: {
          current: listParams.current,
          size: listParams.size,
          dealerCode: listParams.dealerCode,
          dealerNameEn: listParams.dealerName,
          vinNo: listParams.vinNo,
          vinNoList: listParams.vinNoList,
          model: listParams.modelName,
          modelYears: listParams.modelYears,
          offerTypeId: listParams.offerTypeId,
          buLst: (routerParams.programBuIds as string).split(','),
          programId: listParams.progDesignId,
          exemptionDateBegin: listParams.exemptionDateBegin,
          exemptionDateEnd: listParams.exemptionDateEnd,
          exceed: listParams.exceed,
          autoDeduction: listParams.autoDeduction,
          queryDealerTrade: listParams.queryDealerTrade,
          claimAppIdList: [] as any
        }
      };
      const ids: any[] = [];
      if (chooseTableLst.value.length > 0) {
        chooseTableLst.value.forEach((item: any) => {
          ids.push(item.id);
        });
      }
      exportParam.checkingReqVo.claimAppIdList = ids;
      const paramsConfig = {
        url: `/claimapi/paymentPreparation/detail/export/all`,
        method: 'post',
        params: exportParam
      };
      downloadFile(paramsConfig, 'application/vnd-excel; char-set=UTF-8');
    };
    //关闭Cover
    const closeBtnCover = () => {
      isBtnCover.value = false;
      coverDataSource.value.length = 0;
    };
    const getRejectToSICCOData = (type: any) => {
      getParams();
      // const modelYears = ref<any>([]);
      const ids: any[] = [];
      if (chooseTableLst.value.length > 0) {
        chooseTableLst.value.forEach((item: any) => {
          ids.push(item.id);
        });
      } else {
        queryListData.value.forEach((item: any) => {
          ids.push(item.id);
        });
      }

      const params = {
        confirm: type,
        dealerCode: listParams.dealerCode,
        dealerName: listParams.dealerName,
        modelName: listParams.modelName,
        modelYears: listParams.modelYears,
        offerTypeId: listParams.offerTypeId,
        progDesignId: listParams.progDesignId,
        programBus: listParams.programBus,
        vinNo: listParams.vinNo,
        exemptionDateBegin: listParams.exemptionDateBegin,
        exemptionDateEnd: listParams.exemptionDateEnd,
        exceed: listParams.exceed,
        autoDeduction: listParams.autoDeduction,
        queryDealerTrade: listParams.queryDealerTrade,
        ids: ids
      };

      if (type === 'N') {
        getDetilRejectToSICCO(params).then((res) => {
          const dataArr = ref<any>([]);
          dataArr.value = res ? res : {};
          let sum = 0;
          res &&
            res.forEach((i: any) => {
              if (i.qty) {
                sum += parseInt(i.qty);
              }
            });
          dataArr.value.push({ qty: sum });
          coverDataSource.value = dataArr.value;
          if (type === 'N') {
            openBtnCover('rejecttosicco');
          }
        });
      } else if (type === 'Y') {
        Modal.confirm({
          title: 'Tips',
          content: `Do you confirm to reject ${
            chooseTableLst.value.length === 0 ? queryListData.value.length : chooseTableLst.value.length
          } claims to SICCO?`,
          icon: createVNode(ExclamationCircleOutlined),
          okText: 'Confirm',
          centered: true,
          onOk() {
            postDetilRejectToSICCO(params).then((res) => {
              if (type === 'Y') {
                message.success('Request submitted successfully. Please query result in task center');
                closeBtnCover();
                getTableData();
              }
            });
          }
        });
      }
    };
    const getSubbmitControlling = (type: any) => {
      getParams();
      const ids: any[] = [];
      if (chooseTableLst.value.length > 0) {
        chooseTableLst.value.forEach((item: any) => {
          ids.push(item.id);
        });
      } else {
        queryListData.value.forEach((item: any) => {
          ids.push(item.id);
        });
      }
      const params = {
        confirm: type,
        dealerCode: listParams.dealerCode,
        dealerName: listParams.dealerName,
        modelName: listParams.modelName,
        modelYears: listParams.modelYears,
        offerTypeId: listParams.offerTypeId,
        progDesignId: listParams.progDesignId,
        programBus: listParams.programBus,
        vinNo: listParams.vinNo,
        exemptionDateBegin: listParams.exemptionDateBegin,
        exemptionDateEnd: listParams.exemptionDateEnd,
        exceed: listParams.exceed,
        autoDeduction: listParams.autoDeduction,
        queryDealerTrade: listParams.queryDealerTrade,
        ids: ids
      };
      if (type === 'N') {
        getDetilSubmitToCon(params).then((res) => {
          const dataArr = ref<any>([]);
          dataArr.value = res ? res : {};
          let sum = 0;
          res &&
            res.forEach((i: any) => {
              if (i.qty) {
                sum += parseInt(i.qty);
              }
            });
          dataArr.value.push({ qty: sum });
          coverDataSource.value = dataArr.value;
          if (type === 'N') {
            openBtnCover('submittocontrolling');
          }
        });
      } else if (type === 'Y') {
        closeBtnCover();
        getDetailCheckList(params).then((res) => {
          if (res.asyncFlag) {
            Modal.warning({
              title: 'Tips',
              content: 'Submitted claim amount has exceeded the maximum value, data will be processing in system background, please wait!'
            });
            return;
          }

          if (!res.resultVOList?.length) {
            const type = 'Y';
            submitToCon(type);
          } else {
            checkDataSource.value = res.resultVOList;
            Object.assign(checkData, params);
            checkVisible.value = true;
          }
        });
      }
    };

    const submitToCon = (type: any) => {
      getParams();
      const ids: any[] = [];
      if (chooseTableLst.value.length > 0) {
        chooseTableLst.value.forEach((item: any) => {
          ids.push(item.id);
        });
      } else {
        queryListData.value.forEach((item: any) => {
          ids.push(item.id);
        });
      }
      const params = {
        confirm: type,
        dealerCode: listParams.dealerCode,
        dealerName: listParams.dealerName,
        modelName: listParams.modelName,
        modelYears: listParams.modelYears,
        offerTypeId: listParams.offerTypeId,
        progDesignId: listParams.progDesignId,
        programBus: listParams.programBus,
        vinNo: listParams.vinNo,
        exemptionDateBegin: listParams.exemptionDateBegin,
        exemptionDateEnd: listParams.exemptionDateEnd,
        exceed: listParams.exceed,
        autoDeduction: listParams.autoDeduction,
        queryDealerTrade: listParams.queryDealerTrade,
        ids: ids
      };
      postDetilSubmitToCon(params).then((res) => {
        if (type === 'Y') {
          message.success('Request submitted successfully. Please query result in task center');
          closeCheckModal();
        }
      });
    };

    const closeCheckModal = () => {
      checkVisible.value = false;
      chooseTableLst.value = [];
      getTableData();
    };

    const handlDetail = (record: any) => {
      const option = {
        dealerClaimId: record.id,
        programId: record.value,
        offerTypeId: record.value,
        vinNo: record.vinNo,
        id: record.id
      };
      deatilModal.value.showDetail(option);
    };

    const exemptionDateChange = (val: Moment[]) => {
      queryListParams.exemptionDateFrom = val[0];
      queryListParams.exemptionDateTo = val[1];
    };

    return {
      deatilModal,
      handlDetail,
      // getUserInfo,
      chooseTableLst,
      getSubbmitControlling,
      getRejectToSICCOData,
      coverDataSource,
      coverInfo,
      handleExport,
      modelYearLst,
      getModleYear,
      routerParams,
      programCategory,
      ErrorTypeList,
      programName,
      programCode,
      dataSource,
      renderQty,
      renderNo,
      openBtnCover,
      closeBtnCover,
      isBtnCover,
      selectTableIds,
      rowSelection,
      searchCon,
      tableWidth,
      tableHeight,
      coverColumns,
      columns,
      queryListParams,
      queryListData,
      getParams,
      getTableData,
      exportResult,
      pagination,
      changePagination,
      changePageSize,
      clearQueryListParams,
      exemptionDateChange,
      changeVins,
      checkVisible,
      closeCheckModal,
      checkDataSource,
      checkData,
      submitToCon,
      getCheckErrorType
    };
  }
});
