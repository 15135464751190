
interface RetailListType {
    name: string;
    value: string;
    type: string;
    path: string;
}
import axios from 'axios';
import { b64toBlob } from '@/utils/claim/b64toBlob';
import { docReview, claimReview } from '@/API/claim/delaerClaim/delaer';
import {
    ref,
    onMounted,
    nextTick,
    reactive,
    toRefs,
    defineComponent,
} from 'vue';
import {
    documentReview,
    getDetailDocumentsApi,
    getDocumentTable,
    pdfViewApi,
    getDetailOperationRecordApi,
    getDetailRetail as getDetailRetailAPI,
    getRVFile,
} from '@/API/checking/SICCOCheckingDetail';
interface OptionType {
    id: string;
    dealerClaimId: string;
    programId: string;
    offerTypeId: string;
    vinNo: string;
}

interface OperationSourceDataType {
    action: string;
    checkingId: string;
    checkingStatus: string;
    comments: string;
    createdAt: string;
    createdBy: string;
}

interface RetailDataType {
    VIN: string;
    customerName: string;
    eligibility: string;
    invoice: string;
    invoiceAmount: string;
    invoiceDate: string;
    model: string;
    registrtionDate: string;
    retailType: string;
    vehicleLicense: string;
    invoiceType: string;
    vehicleLicenseType: string;
    invoiceStr: string;
    vehicleLicenseStr: string;
    dealerTrade: string | null
    dealerTradeWholeSaleCode: string | null
    dealerTradeWholeSaleNameCn: string | null
}

export default defineComponent({
    setup() {
        const option = ref<OptionType>({
            id: 'string',
            dealerClaimId: 'string',
            programId: 'string',
            offerTypeId: 'string',
            vinNo: 'string',
        });
        //加载数据状态
        const spinning = ref(false);

        const visible = ref(false);
        const reviewVisible = ref(false);
        const RVVisable = ref(false);

        const RVFile = ref('');
        const RVType = ref('');
        const isComplete = ref(false);

        const activeKey = ref('1');

        //Document data数据
        const documentSourceData = ref<any[]>([]);
        //Document data 表头
        const documentColumns = ref<any[]>([]);
        //Document slot
        const documentSolt = ref<any[]>([]);
        //Document 表格宽度
        const documentTableWidth = ref(950);

        //Operation Recodrd表格
        const operationColumns = [
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                width: 230,
            },
            {
                title: 'Operation User',
                key: 'createdBy',
                dataIndex: 'createdBy',
                width: 130,
            },
            {
                title: 'Operation Time',
                key: 'oprationTime',
                dataIndex: 'oprationTime',
                width: 180,
            },
            {
                title: 'Comments',
                key: 'comments',
                slots: { customRender: 'comments' },
            },
        ];

        const operationSourceData = ref<OperationSourceDataType[]>([]);

        //Reatail的数据
        const retailData = ref<RetailDataType>({
            VIN: '',
            customerName: '',
            eligibility: '',
            invoice: '',
            invoiceAmount: '',
            invoiceDate: '',
            model: '',
            registrtionDate: '',
            retailType: '',
            vehicleLicense: '',
            invoiceType: '',
            vehicleLicenseType: '',
            invoiceStr: '',
            vehicleLicenseStr: '',
            dealerTrade: null,
            dealerTradeWholeSaleCode:  null,
            dealerTradeWholeSaleNameCn:  null
        });

        function confirmEnding(str: string, target: string) {
            // 请把你的代码写在这里
            const start = str.length-target.length;
            const arr = str.substr(start,target.length);
            if (arr == target){
                return true;
            }
            return false;
        }
        //获取Retail数据
        const getDetailRetail = (res: any) => {
            const one = res as RetailDataType;
            retailData.value = res as RetailDataType;
            retailData.value.VIN = option.value.vinNo;

            if (one.invoice.length > 0) {
                // if (one.invoice.split('/')[1] === 'JPG') {
                if (confirmEnding(one.invoice,'jpg')|| confirmEnding(one.invoice,'JPG')) {
                    retailData.value.invoiceType = 'jpg';
                } else {
                    retailData.value.invoiceType = 'pdf';
                }
                retailData.value.invoiceStr =
                    'IN_' +
                    retailData.value.VIN +
                    '.' +
                    retailData.value.invoiceType;
            } else {
                retailData.value.invoiceStr = '';
            }
            retailData.value.invoice = one.invoice.split('/')[0];

            if (one.vehicleLicense.length > 0) {
                // if (one.vehicleLicense.split('/')[1] === 'JPG') {
                if (confirmEnding(one.vehicleLicense,'jpg')|| confirmEnding(one.vehicleLicense,'JPG')) {
                    retailData.value.vehicleLicenseType = 'jpg';
                } else {
                    retailData.value.vehicleLicenseType = 'pdf';
                }
                retailData.value.vehicleLicenseStr =
                    'RE_' +
                    retailData.value.VIN +
                    '.' +
                    retailData.value.vehicleLicenseType;
            } else {
                retailData.value.vehicleLicenseStr = '';
            }
            retailData.value.vehicleLicense = one.vehicleLicense.split(
                '/'
            )[0];
        };

        //处理Document返回的数据，并赋值
        const handelDocement = (data: any) => {
            if (data === null || data.length === 0) {
                return;
            }
            // const data =[
            //     [
            //         "",
            //         "申诉2",
            //         "申述1"
            //     ],
            //     [
            //         "其他文件",
            //         {
            //             "supportingDocId": "supportingDocId3",
            //             "supportingDocReName": "789.txt",
            //             "supportDocType": "supportDocType3"
            //         },
            //         {
            //             "supportingDocId": "supportingDocId3",
            //             "supportingDocReName": "789.txt",
            //             "supportDocType": "supportDocType3"
            //         }
            //     ],
            //     [
            //         "市场活动报告2",
            //         "",
            //         {
            //             "supportingDocId": "supportingDocId2",
            //             "supportingDocReName": "456.txt",
            //             "supportDocType": "supportDocType2"
            //         }
            //     ],
            //     [
            //         "市场活动报告1",
            //         "",
            //         {
            //             "supportingDocId": "supportingDocId1",
            //             "supportingDocReName": "123.txt",
            //             "supportDocType": "supportDocType1"
            //         }
            //     ]
            // ]
            // {
            //     name:'其他文件',
            //     rep1:'',
            //     rep2:'name2',
            // }

            const columnsArray = []; //表头
            const dataSourceColumnsArray = []; //table数据
            const soltArray = []; //插槽名称
            let tableWidth = 0;
            //生成表头,和数据对象的key
            for (let i = 0; i < data[0].length; i++) {
                if (i === 0) {
                    columnsArray.push({
                        title: '',
                        dataIndex: 'name',
                        key: 'name',
                    });
                } else {
                    columnsArray.push({
                        title: data[0][i],
                        dataIndex: 'rep' + i,
                        key: 'rep' + i,
                        slots: { customRender: 'rep' + i },
                    });
                    soltArray.push('rep' + i);
                    tableWidth += 300;
                }
            }
            //生成数据
            for (let i = 1; i < data.length; i++) {
                const onerow = data[i]; //要处理的每一行数据

                const rowData = {}; //最终生成的行对象
                rowData['name'] = onerow[0];
                for (let j = 1; j < onerow.length; j++) {
                    if (onerow[j] !== '') {
                        rowData[columnsArray[j].dataIndex] = (onerow[
                            j
                        ] as any).supportingDocReName;
                        rowData[
                            columnsArray[j].dataIndex + 'supportingDocId'
                        ] = (onerow[j] as any).supportingDocId;
                        rowData[
                            columnsArray[j].dataIndex + 'supportDocType'
                        ] = (onerow[j] as any).supportDocType;
                    } else {
                        rowData[columnsArray[j].dataIndex] = '';
                    }
                }
                dataSourceColumnsArray.push(rowData);
            }
            documentColumns.value = columnsArray;
            documentSourceData.value = dataSourceColumnsArray;
            documentSolt.value = soltArray;
            if (tableWidth > documentTableWidth.value) {
                documentTableWidth.value = tableWidth;
            }
            //handelDocement
        };

        //显示抽屉
        const showDetail = (item: OptionType) => {
            spinning.value = false;
            option.value.programId = item.programId;
            option.value.offerTypeId = item.offerTypeId;
            option.value.dealerClaimId = item.dealerClaimId;
            option.value.vinNo = item.vinNo;
            option.value.id = item.dealerClaimId;
            // option.value.vinNo = 'LE44N8HB6ML204858';
            // option.value.vinNo = 'WDCDA5GB0HA854182';
            activeKey.value = '1';
                        //清空数据
            operationSourceData.value = [];
            documentColumns.value = [];
            documentSourceData.value = [];
            documentSolt.value = [];
            retailData.value = {
                VIN: "",
                customerName:"",
                eligibility: "",
                invoice: "",
                invoiceAmount: "",
                invoiceDate:"",
                model:"",
                registrtionDate: "",
                retailType: "",
                vehicleLicense: "",
                invoiceType:"",
                vehicleLicenseType: "",
                invoiceStr: "",
                vehicleLicenseStr: "",
                dealerTrade: null,
                dealerTradeWholeSaleCode:  null,
                dealerTradeWholeSaleNameCn:  null
            }

            const document = getDocumentTable({
                dealerClaimId: item.dealerClaimId,
            });
            const record = getDetailOperationRecordApi({
                dealerClaimId: option.value.dealerClaimId,
            });
            const retail = getDetailRetailAPI({  dealerClaimId: item.dealerClaimId });

            Promise.all([document, record, retail]).then((result) => {
                // documentSourceData.value=result[0];
                handelDocement(result[0]);

                const data = result[1];
                for (let i = 0; i < data.length; i++) {
                    if (
                        data[i].comments !== null &&
                        data[i].comments !== undefined &&
                        data[i].comments !== ''
                    ) {
                        console.log(data[i].comments);
                        data[i].comments = data[i].comments?.replace(
                            /\\n/g,
                            ' '
                        );
                    }
                }
                operationSourceData.value = data;

                getDetailRetail(result[2]);
                visible.value = true;
            });
        };

        const fileType = ref('');
        const base64code = ref('');

        //显示document文档
        const showFile2 = (supportingDocId: string, supportDocType: string) => {
            // spinning.value = true;
            documentReview({
                supportingDocId: supportingDocId,
                supportingDocType: supportDocType,
            }).then((res: any) => {
                spinning.value = false;
                if (res.ext === 'jpg') {
                    base64code.value =
                        'data:image/jpg;base64,' + res.base64code;
                } else if (res.ext === 'pdf') {
                    base64code.value = URL.createObjectURL(
                        b64toBlob(res.base64code, 'application/pdf')
                    );
                }
                visible.value = false;
                reviewVisible.value = true;
                fileType.value = res.ext;
            });
        };

        //显示图片或pdf
        const showFile = (item: any, id: string) => {
            visible.value = false;
            if (item.type === 'doc') {
                //

                docReview({ collectionDocId: id }).then((res: any): void => {
                    reviewVisible.value = true;
                    // console.log(res)
                    fileType.value = res.ext;
                    if (res.ext === 'jpg') {
                        base64code.value =
                            'data:image/jpg;base64,' + res.base64code;
                    } else if (res.ext === 'pdf') {
                        base64code.value = URL.createObjectURL(
                            b64toBlob(res.base64code, 'application/pdf')
                        );
                    }
                });
            } else {
                claimReview({ id: id }).then((res: any): void => {
                    reviewVisible.value = true;
                    // console.log(res)
                    fileType.value = res.ext;
                    if (res.ext === 'jpg') {
                        base64code.value =
                            'data:image/jpg;base64,' + res.base64code;
                    } else if (res.ext === 'pdf') {
                        base64code.value = URL.createObjectURL(
                            b64toBlob(res.base64code, 'application/pdf')
                        );
                    }
                });
            }
            // Modal.confirm({
            //     title: 'Please confirm',
            //     content: 'Do you confirm to delete current file?',
            //     okText: 'Confirm',
            //     cancelText: 'Cancel',
            // });
        };

        //显示发票
        const showIN = () => {
            if (
                retailData.value.invoice === '' ||
                retailData.value.invoice.length <= 1
            ) {
                return;
            }
            // RVVisable.value = true;
            visible.value = false;
            RVType.value = '';
            // spinning.value = true;
            isComplete.value = false;
            if (retailData.value.invoiceType === 'pdf') {
                const path = retailData.value.invoice + '/PDF';
                pdfViewApi({ licensePath: path, fileName: 'xx' }).then(
                    (res) => {
                        // console.log(res)
                        RVFile.value = URL.createObjectURL(
                            b64toBlob(res.base64, 'application/pdf')
                        );
                        spinning.value = false;
                        RVType.value = 'PDF';
                        isComplete.value = true;
                        RVVisable.value = true;
                    }
                );
            } else {
                getRVFile({
                    id: retailData.value.invoice,
                    type: retailData.value.invoiceType,
                    fileName: retailData.value.VIN,
                }).then((res) => {
                    RVFile.value = res.downloadUrl;
                    RVType.value = res.docType;
                    spinning.value = false;
                    isComplete.value = true;
                    RVVisable.value = true;
                });
            }
        };

        //显示行驶证
        const showRE = () => {
            if (
                retailData.value.vehicleLicense === '' ||
                retailData.value.vehicleLicense.length <= 1
            ) {
                return;
            }
            RVType.value = '';
            isComplete.value = false;
            visible.value = false;
            // spinning.value = true;
            if (retailData.value.invoiceType === 'pdf') {
                const path = retailData.value.vehicleLicense + '/PDF';
                pdfViewApi({ licensePath: path, fileName: 'xx' }).then(
                    (res) => {
                        RVFile.value = URL.createObjectURL(
                            b64toBlob(res.base64, 'application/pdf')
                        );
                        spinning.value = false;
                        isComplete.value = true;
                        RVType.value = 'PDF';
                        RVVisable.value = true;
                    }
                );
            } else {
                getRVFile({
                    id: retailData.value.vehicleLicense,
                    type: retailData.value.vehicleLicenseType,
                    fileName: retailData.value.VIN,
                }).then((res) => {
                    RVFile.value = res.downloadUrl;
                    RVType.value = res.docType;
                    spinning.value = false;
                    isComplete.value = true;
                    RVVisable.value = true;
                });
            }
        };

        const retailList = ref<RetailListType[]>([]);

        const onCancel = () => {
            console.log('onCancel');
            // if (isComplete.value === false)
            visible.value = true;
        };

        const onCancel2 = () => {
            console.log('onCancel2');
            visible.value = true;
        };

        return {
            showIN,
            showRE,
            RVVisable,
            RVFile,
            RVType,
            isComplete,
            onCancel,
            onCancel2,
            documentTableWidth,
            spinning,
            reviewVisible,
            fileType,
            base64code,
            operationColumns,
            operationSourceData,
            retailData,
            showDetail,
            visible,
            activeKey,
            retailList,
            showFile,
            showFile2,
            documentColumns,
            documentSourceData,
            documentSolt,
        };
    },
});
